.loader-container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 100;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
}
.loader-container img {
  height: 30px;
  margin: 10px 20px;
}
.surface {
  width: 360px;
  height: 50px;
  margin-bottom: 40px;
  justify-content: center;
}
@media only screen and (max-width: 900px) {
  .loader-container img {
    height: 20px;
    margin: 10px 10px;
  }
  .surface {
    width: 210px;
  }
}
