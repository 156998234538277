.sectionfaq {
  align-items: flex-start;
  overflow-x: hidden;
}
.faqcontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  max-width: 1400px;
  align-items: flex-start;
  overflow-y: hidden;
  height: auto;
  margin: 60px;
}

.faqtitle {
  font-size: 10rem;
  margin: 0px;
  line-height: 9rem;
}

.faqcontainer h2 {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.faqcontainer li {
  list-style-type: disc;
  margin-bottom: 6px;
}

.faqcontainer li::marker {
  color: var(--accent-color);
  font-size: 16px;
  line-height: 14px;
  font-weight: bold;
}

.faqcontainer p {
  font-size: 16px;
}

.faqcontainer li {
  font-size: 12px;
}

@media only screen and (max-width: 900px) {
  .sectionfaq {
    padding: 0px 10px;
    align-items: center;
  }
  .faqcontainer {
    /* width: 100vw; */
    grid-template-columns: none;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    margin: 0px;
    grid-gap: 0px;
    row-gap: 0px;
    overflow-y: scroll;
  }
  .faqcontainer h1 {
    font-size: 100px;
  }
  .faqcontainer p {
    text-align: start;
  }
}
