.listTile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: var(--bg-color); */
  max-width: 760px;
  width: 100%;
  height: 40px;
  padding: 0px 20px;
  border-radius: var(--border-radius);
  margin: 10px 0px;
}

.leading {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: #70e2fb;
  background-image: var(--fortnite-gradient);
}

.title {
  font-weight: bold;
  margin-left: 40px;
  font-size: 24px;
  justify-content: start;
  width: 100%;
}
.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;
}
.subtitle span{
    margin: 10px;
}
.childrens div {
  margin: 4px 10px;
}
.icon-button {
  cursor: pointer;
  border: 2px solid white;
  border-radius: 50px;
  /* height: 50px; */
  aspect-ratio: 1/1;
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
}
.icon-button:hover{
  border: 2px solid #ab54ea;
  transition: 0.2s ease-out;
  color: #ab54ea;
}
