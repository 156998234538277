.fade-back{
  height: 100vh;
  width: 100vw;
  background-color: black;
  position: absolute;
  top: 0px;
  z-index: 5;
  display: flex;
  animation: fadeIn 200ms ease-in 0ms 1 forwards;
}
.close-fade{
  animation: fadeIn 100ms ease-in 0ms 1 reverse;

}
@keyframes fadeIn {
  from{opacity: 0;}
  to{opacity: 0.95;}
  
}
.dialog-container {
  padding: 30px;
  color: var(--accent-color);
  border: 2px solid var(--border-color);
  background-color: var(--bg-color);
  border-radius: 30px;
  position: fixed;
  bottom: -100vh;
  z-index: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: dialog-open 0.4s ease-in 0s 1 normal forwards;
  opacity: 1;
  text-align: center;
  overflow: hidden;
}
.video-dialog-back{
  position: absolute;
  left: 0px;
  height: 600px;
  width: 400px;
  z-index: -1
}
.dialog-container p {
  letter-spacing: 4px;
}
.close {
  /* display: none; */
  animation: dialog-close 0.4s ease-in 0s 1 reverse forwards;
}

@keyframes dialog-open {
  0% {
    bottom: -100vh;
  }
  100% {
    bottom: 50vh;
    transform: translateY(50%);
  }
}

@keyframes dialog-close {
  100% {
    bottom: 100px;
    opacity: 1;
  }

  0% {
    bottom: -100vh;
    opacity: 0;
  }
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
}
.inner-container label{
  width: 70px;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 400px;
  border-radius: 12px;
}

.image-container img {
  height: 100%;
}

.title-logo {
  height: 80px;
}
.icon {
  margin-right: 20px;
}
.row-inputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
}
.bgcheckbox{
  display: block;
  position:relative;
  border: 1px solid var(--accent-color);
  height: 14px;
  width: 14px;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
}
.checkmark {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--accent-color);
}


.bgcheckbox p{
  margin: 30px 0px 20px 0px;
  letter-spacing: 1px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
}
.dialog-button {
  width: 100px;
}

.dialog-input {
  margin: 20px;
  width: 100%;
  border: 2px solid var(--border-color);
}
.error{
  border: 2px solid red;
}
.close-button {
  position: absolute;
  left: 40px;
  color: var(--accent-color);
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .row-inputs {
    flex-direction: column;
  }
  @keyframes dialog-open {
    0% {
      bottom: -100vh;
    }
    100% {
      bottom: 60px;
    }
  }
  .dialog-input {
    margin: 0px;
    width: 100%;
  }
  .inner-container label{
  width: auto;
  min-width: none;
}
  .inner-container {
    padding: 0px;
  }
  .dialog-container p {
    font-size: 10px;
    letter-spacing: 0px;
  }
  .dialog-container {
    padding: 30px 10px 10px 10px;
    height: auto;
  }
  .title-logo {
    height: 60px;
  }
  
  .close-button {
    /* left: 0px; */
    /* top: 10px; */
  }
}
