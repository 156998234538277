.card-item-destacado-container {
  display: grid;
  grid-template-columns: auto auto auto;
  min-width: 800px;
  z-index: 0;
  
}

@keyframes fade-card-animation {
  0% {
    opacity: 0;
}
100% {
    opacity: 1;
  }
}

.card-item-destacado {
  width: 200px;
  aspect-ratio: 1/1;
  margin: 20px 0px;
  background-color: var(--inputs-bg-color);
  background-size: 200px;
  background-position: center;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  list-style-type: none;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-image: var(--fortnite-gradient);
  opacity: 0;
  animation: fade-card-animation 0.4s ease-in 0s 1 forwards;
  transition: ease;
  transition-duration: 0.2s;
  overflow: hidden;


}
.card-item-destacado:hover{
    border-color: white;
    transition: ease-in;
    transition-duration: 0.2s;
    background-size: 110%;
}

.card-item-destacado-title {
  text-align: center;
  position: absolute;
  bottom: 0px;
  /* transform: translateY(100%); */
  left: 0px;
  text-shadow: 0px 0px 4px black;
  width: 200px;
  font-size: 18px;
  border-radius: 12px;
  
}

img {
  /* border-radius: var(--border-radius); */
  height: 200px;
}
.float-button{
  opacity: 1;
  font-size: 8px;
  position: absolute;
  height: 12px;
  width: 12px;
  top: 4px;
  right: 4px;
}






