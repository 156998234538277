h2 {
  padding: 20px;
  margin: 20px;
}

.form-container {
  width: 100vw;
  min-height: 90vh;
  max-width: 1600px;
  display: flex;
  padding: 100px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
}

.header-videojuego {
  display: flex;
  flex-direction: column;
  text-align: start;
  color: var(--accent-color);
}

.header-videojuego h2 {
  margin: 0px;
  padding: 0px;
  font-size: 36px;
  font-weight: bold;
}

.header-videojuego b {
  font-size: 16px;
}

.icon-control {
  color: var(--accent-color);
  height: 64px;
  margin-right: 20px;
}

.game-title-sticky {
  /* position: sticky; */
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.value-container {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  width: 220px;
  border: 1px solid var(--accent-color);
  border-radius: 100px;
  color: var(--accent-color);
  /* position: sticky; */
  top: 0;
  padding: 0px 20px;
  z-index: 4;
  margin: 20px 0px;
}

.label {
  font-weight: bold;
}

.value-estimate {
  font-size: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 60px;
}

.valor-estimado {
  font-size: 48px;
}

.grid {
  /* width: 100vw; */
  display: flex;
  /* grid-template-columns: auto auto; */
  flex-wrap: wrap;

  /* column-gap: 80px; */
  justify-content: center;
}

.submit {
  width: 300px;
  border-radius: 12px;
  background: var(--gradient);
  margin: 20px;
  font-size: 16px;
  height: 60px;
  padding: 0px 20px;
  font-weight: bold;
  transition: 0.4s ease-in;
}

.submit:hover {
  transition: 0.4s ease-in;
  /* background: orange; */
  box-shadow: 2px 2px 20px #314180;
}

.button-next {
  font-size: 16px;
  width: 100px;
}

.search-bar {
  width: 100%;
  background-color: var(--accent-color);
  color: black;
  padding-left: 40px;
}

.search-bar ::placeholder {
  padding-left: 40px;
}

.items-search-container {
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 200px;
  border: 2px solid var(--accent-color);
  border-radius: 40px;
  overflow-y: scroll;
  width: 100%;
  transition: ease-in 2s;
}

.items-search-container::-webkit-scrollbar {
  width: 10px;
}

.hidebg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 900%;
  background-color: rgba(0, 0, 0, 0.94);
  z-index: 5;
  scroll-snap-align: none;
  animation: hidebg-fade 0.2s ease-in 0s 1 forwards;
}

@keyframes hidebg-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.9;
  }
}

section {
  /* width: 1400px; */
  align-items: center;
  margin: 0px;
  /* flex: 1; */
  /* width: 100vw; */
  /* height: 400px; */
}

@media only screen and (max-width: 900px) {
  .form-container {
    padding: 0px 0px 0px 0px;
    overflow-x: hidden;
  }
  .calculadora-padding{
    padding-bottom: 300px;
  }

  .grid {
    align-items: flex-start;
    grid-template-columns: none;
    display: flex;
    width: 100vw;
    padding-bottom: 100px;
  }

  .game-title-sticky {
    position: sticky;
    top: 0px;
    padding-top: 20px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(0, 0, 0, 1) 70%
    );
  }
  .valor-estimado{
    font-size: 24px;
  }
  .header-videojuego h2 {
    font-size: 24px;
  }
  .icon-control {
    height: 32px;
  }
  .value-container {
    margin: 10px 0px;
  }
}
