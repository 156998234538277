.gsSection {
    padding: 100px;
    margin: 0px;
    text-align: center;
}

.grid-items {
    width: 360px;
    margin: 40px;
}

.grid-items img {
    height: 24px;
    margin-right: 20px;
}

.label-value-item {
    font-size: 12px;
    margin: 0px;
}

.value-estimate {
    flex-direction: column;
    padding: 8px;
}
/* .valor-estimado{
    line-height: 28px;
    margin-bottom: 8px;
} */