@font-face {
  font-family: grotesk;
  src: url(./assets/fonts/PowerGrotesk-Regular.ttf);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
}

:root {
  --bg-color: rgb(0, 0, 0);
  --accent-color: #8ffdff;
  --inputs-bg-color: none;
  --border-color: #8ffdff;
  --surface-color: #0a112c;
  --surface-shadow-color: #a9aeba;
  --on-surface-color: white;
  --gradient: linear-gradient(90deg, #5307d9, #ab54ea);
  --fortnite-gradient: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.6) 90%
  );
  --border-radius: 12px;
}

html {  
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  background-color: var(--bg-color);
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 6px;
}

body {
  
  /* font-family: "HelveticaNeue-CondensedBold", Helvetica, Arial, sans-serif; */
  font-family: grotesk;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--accent-color);
}

.homebg, .calculadora-video{
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -2;
  height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.main-video {
  transform: rotateX(0);
}

.video-faqs {
  height: 100vh;
  width: 100vw;
}

.homebg video {
  /* height: 100vh; */
  /* width: 100%; */
  background-color: black;
  position: absolute;
  top: 0px;
  right: 0px;
}

.footer {
  /* margin-top: 20px; */
  position: relative;
  /* bottom: 20px; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  font-size: 12px;
}
.footer-home {
  margin-top: 20px;
  position: relative;
  bottom: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  font-size: 12px;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 60% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

h1 {
  font-size: 36px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #00fdff;
}

p {
  font-weight: normal;
}

section {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  scroll-snap-align: center;
  color: white;
  position: relative;
}

.section-gamesoptions {
  height: auto;
}

button {
  border: none;
  color: var(--on-surface-color);
  background-color: rgba(0, 0, 0, 0);
}

Ï.App {
  
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
}

.App-header {
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  margin: 0px;
  padding: 0px;
}

input {
  border: none;
  padding: 0px 8px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  border-radius: 30px;
  background-color: var(--inputs-bg-color);
}

.section-container {
  position: absolute;
  overflow-y: none;
  max-height: 100vh;
}

.calculadora {
  /* overflow-y: scroll; */
  overflow-x: hidden;
  position: fixed;
  left: 0px;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  /* padding-bottom: 100px; */
}

.calculadora-buttons {
  display: flex;
  flex-direction: row;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .section-container {
    overflow-y: scroll;
  }

  .section-container::-webkit-scrollbar {
    width: 0px;
  }

  .section-container::-webkit-scrollbar-track {
    background-color: var(--bg-color);
  }

  section {
    height: 100dvh;
    /* height: 100dvh; */
  }

  .homebg {
    /* height: 100vh; */
    height: 100dvh;
    overflow: hidden;
  }
  .main-video {
    /* transform: rotateZ(270deg); */
    height: 100vh;
  }
  
  .main-video video {
    height: 70vh;
  }
  .calculadora-video{
    z-index: -4;
    height: 90vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0px;
  }

  .calculadora {
    padding-top: 0px;
    align-items: flex-start;
    overflow-y: hidden;
  }
  .calculadora-buttons {
    position: fixed;
    bottom: 40px;
    z-index: 2;
    margin: 0px;
  }
  .footer {
    z-index: 1;
    height: 10px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0.5) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding: 80px 0px 20px 0px;
    font-size: 8px;

  }
}
