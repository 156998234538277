.registrado-section-container{
display: flex;
flex-direction: column;
width: 460px;
font-size: 24px;
text-align: center;
}
.registrado-section-container strong{
    font-weight: bold;
    font-size: 36px;
    color: var(--accent-color);
}

@media only screen and (max-width: 900px) {
    .registrado-section-container{
        width: 98vw;
        text-align: center;
        font-size: 16px;
        align-items: center;
    }
}