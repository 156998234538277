.games-section{
  height: 300px;
}
.section-games-options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  /* grid-template-columns: auto auto auto auto; */
  z-index: 2;
}

.game-cards {
  font-size: 12px;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  height: 260px;
  width: 260px;
  overflow: hidden;
  border-radius: 40px;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 25px;
  background-position: center;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  padding: 10px 20px;
  transition: 0.1s ease-in;
  border: 0px solid black;
  background-repeat: no-repeat;
  object-fit: cover;
}

.game-cards:hover {
  background-color: rgba(0, 0, 0, 0);
  transition: 0.1s ease-in;
}

.container-info {
  background-color: rgba(0, 0, 0, 0.6);
  width: 240px;
  border-radius: 50px;
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.container-info img{
  height: 12px;
  margin-right: 4px;
  
}

.info-column {
  display: flex;
  flex-direction: column;
}

.game-title {
  font-size: 12px;
  font-weight: bold;
  width: 126px;
}

.game-value {
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 50px;
  color: var(--accent-color);
}
.game-value b{
  font-size: 16px;
  margin-right: 6px;
}

@media only screen and (max-width: 1600px) {
  .section-games-options {
    padding: 0px;
    width: 100%;
    height: auto;
  }
    .game-cards {
        height: 250px;
        width: 250px;
    }
}


@media only screen and (max-width: 900px) {
  .section-gamesoptions {
    height: max-content;
  }
  .section-container {
    height: auto;
    padding-top: 20px;
  }
  
  .section-games-options {
    /* padding-bottom: 60px;
    grid-template-columns: none;
    
    display: flex;
    align-items: center;
    flex-direction: column; */
    
  }
}
