.input-card {
  width: 660px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0px;
}

.server-option {
  text-align: center;
  width: 660px;
  border-radius: 30px;
  background-color: var(--accent-color);
  height: 40px;
  font-size: 18px;
}
.server-option select {
  display: none;
}
.server-option option {
  background-color: rgba(255, 255, 255, 0);
  width: 300px;
}

label {
  word-spacing: 2px;
  font-weight: 600;
  padding: 10px;
  font-size: 18px;
  color: var(--accent-color);
  font-weight: normal;
  max-lines: 1;
  width: auto;
  height: 20px;
  width: auto;
  white-space: nowrap;
  max-lines: 1;
  margin-right: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}
.row-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 80px;
  height: 40px;
  padding-right: 10px;
}

.input-items {
  background-color: var(--accent-color);
  color: black;
  width: 100%;
}
.options-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.iconButton {
  height: 36px;
  font-size: 32px;
  padding: 0px;
  width: 36px;
  background-color: var(--accent-color);
  color: black;
  border-radius: 30px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 2px;
}
.mas {
  right: 40px;
}
.menos {
  right: 2px;
}

.iconButton:hover {
  background-color: rgba(black, 1);
}
.outlined-radio {
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  border: 2px solid var(--accent-color);
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
}
.outlined-radio input {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .input-card {
    width: 360px;
    padding: 10px;
    margin: 10px 0px;
  }
  label {
    font-size: 16px;
    max-width: 260px;
    /* min-width: 50%; */
    margin-right: 10px;
    white-space: normal;
    /* background-color: red; */
    max-lines: 2;
    display: flex;
    text-overflow: ellipsis;
  }
  .input-items {
    background-color: var(--accent-color);
    color: black;
    width: 100%;
    text-align: center;
    height: 40px;
  }
  .row-buttons {
    width: 100%;
    justify-content: space-between;
    padding: 0px;
  }
  .input-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    width: 100%;
  }
  .mas {
    right: 2px;
  }
  .menos {
    left: 2px;
    right: 0px;
    margin-left: 0px;
  }
}
